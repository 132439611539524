// import { component } from "v-viewer";
import { createRouter, createWebHistory } from "vue-router";

import store from "../store/index.js";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/v2/Login.vue")
  },
  {
    path: "/su-permissions",
    name: "SuperUserPermissions",
    component: () => import("@/views/v2/SuperUserPermissions.vue")
  },
  {
    path: "/su-george-report",
    name: "SuperUserGeorgeReport",
    component: () => import("@/views/v2/SuperUserGeorgeReport.vue")
  },
  {
    path: "/su-new-user",
    name: "SuperUserNewDealer",
    component: () => import("@/views/v2/SuperUserNewDealer.vue")
  },
  {
    path: "/",
    component: () => import("@/components/Layouts/AnonLayout.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/HomeLanding.vue")
      },
      {
        path: "logout",
        name: "Logout",
        component: () => import("@/views/ViewsLogout.vue")
      },
      {
        path: "book-demo",
        name: "BookDemo",
        component: () => import("@/views/BookDemo.vue")
      },
      {
        path: "about-us",
        name: "AboutUs",
        component: () => import("@/views/AboutUs.vue")
      },
      {
        path: "help-center",
        name: "HelpCenter",
        component: () => import("@/views/HelpCenter.vue")
      },
      {
        path: "contact-us",
        name: "ContactUs",
        component: () => import("@/views/ContactUs.vue")
      },
      {
        path: "reachout/:formId-:messageId-:planId",
        name: "AcceptRemarketingOpportunity",
        component: () => import("@/views/AcceptRemarketingOpportunity.vue")
      },
      {
        path: "optout/:formId-:messageId-:planId",
        name: "RejectRemarketingOpportunity",
        component: () => import("@/views/RejectRemarketingOpportunity.vue")
      },
      {
        path: "maintenance",
        name: "Maintenance",
        component: () => import("@/views/Maintenance.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/components/Layouts/v2/MainLayout.vue"),
    children: [
      {
        path: "switch-dealer",
        name: "SwitchDealer",
        component: () => import("@/views/v2/SwitchDealer.vue")
      },
      {
        path: "first-time-login",
        name: "FirstTimeLogin",
        component: () => import("@/views/v2/FirstTimeLogin.vue")
      },
      {
        path: "search",
        // alias: ["/"],
        name: "Search",
        component: () => import("@/views/v2/Search.vue")
      },
      {
        path: "form-pdf-:id",
        name: "FormPDF",
        component: () => import("@/views/v2/Form/FormPDF.vue")
      },
      {
        path: "fleet-pdf-:id/:noprint?",
        name: "FleetPDF",
        component: () => import("@/views/v2/Form/FormPDF.vue")
      },
      {
        path: "form-:id",
        name: "Form",
        component: () => import("@/views/v2/Form/Form.vue")
      },
      {
        path: "ModelCode-:year-:mc",
        name: "ModelCode",
        component: () => import("@/views/v2/Form/ModelCode.vue")
      },
      {
        path: "pending-arrival",
        name: "PendingArrival",
        component: () => import("@/views/v2/PendingArrivals.vue")
      },
      {
        path: "permissions",
        name: "Permissions",
        component: () => import("@/views/v2/Permissions.vue")
      },
      {
        path: "departments",
        name: "Departments",
        component: () => import("@/views/v2/Departments.vue")
      },
      {
        path: "internal-documents",
        name: "InternalDocuments",
        component: () => import("@/views/v2/InternalDocuments/InternalDocuments.vue")
      },
      {
        path: "tenders",
        name: "Tenders",
        component: () => import("@/views/v2/Tender/Tenders.vue")
      },
      {
        path: "tender-:id",
        name: "Tender",
        component: () => import("@/views/v2/Tender/Tender.vue")
      },
      {
        path: "reseller-list",
        name: "ResellerList",
        component: () => import("@/views/v2/ResellerList.vue")
      },
      {
        path: "global-markets",
        name: "GlobalMarkets",
        component: () => import("@/views/v2/Autograb/GlobalMarkets.vue")
      },
      {
        path: "global-market-fid:formId",
        name: "GlobalMarket",
        component: () => import("@/views/v2/Autograb/GlobalMarket.vue")
      },
      {
        path: "global-market-agfdid:agfdId",
        name: "GlobalMarketFormless",
        component: () => import("@/views/v2/Autograb/GlobalMarket.vue")
      },
      {
        path: "offsite-forms",
        name: "OffsiteForms",
        component: () => import("@/views/v2/OffsiteForms.vue")
      },
      {
        path: "remarketings",
        name: "Marketings",
        component: () => import("@/views/v2/Remarketing/Remarketings.vue")
      },
      {
        path: "marketing-:id",
        name: "Marketing",
        component: () => import("@/views/v2/Remarketing/Remarketing.vue")
      },
      {
        path: "account",
        name: "Account",
        component: () => import("@/views/v2/Account.vue")
      },
      {
        path: "tables",
        name: "Tables",
        component: () => import("@/views/v2/Analytics/Tables.vue")
      },
      {
        path: "reports",
        name: "Reports",
        component: () => import("@/views/v2/Analytics/Reports.vue")
      },
      {
        path: "pre-builts",
        name: "PreBuilts",
        component: () => import("@/views/v2/Analytics/PreBuilts.vue")
      },
      {
        path: "privacy-policy",
        name: "PrivacyPolicy",
        component: () => import("@/views/v2/Legal/PrivacyPolicy.vue")
      },
      {
        path: "data-security-policy",
        name: "DataSecurity",
        component: () => import("@/views/v2/Legal/DataSecurityPolicy.vue")
      },
      {
        path: "terms-and-conditions",
        name: "TermsAndConditions",
        component: () => import("@/views/v2/Legal/TermsAndConditions.vue")
      },
      {
        path: "refund-policy",
        name: "RefundPolicy",
        component: () => import("@/views/v2/Legal/RefundPolicy.vue")
      },
      {
        path: "dms-interface",
        name: "DMSInterface",
        component: () => import("@/views/v2/DMSInterface.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/components/Layouts/CustomerLayout.vue"),
    children: [
      {
        path: "/offsite-appraisal-request/:uuid",
        name: "OffsiteFormCustomer",
        component: () => import("@/views/OffsiteFormCustomer.vue")
      },
      {
        path: "/v/reset-password/:uuid/:token",
        name: "PasswordReset",
        component: () => import("@/views/v2/PasswordReset.vue")
      }
    ]
  },
  {
    path: "/internal-document/:id",
    name: "InternalDocument",
    component: () => import("@/views/v2/InternalDocuments/InternalDocument.vue")
  },
  {
    path: "/dealer-pack/:id",
    name: "DealerPack",
    component: () => import("@/views/v2/InternalDocuments/InternalDocument.vue")
  },
  // 404 Page not found
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/HomeLanding.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const inMaintenance = process.env.VUE_APP_MAINTENANCE_MODE === "true";
  if (inMaintenance) {
    if (to.name == "Maintenance") {
      next();
    } else {
      next({ name: "Maintenance" });
    }

    return;
  } else {
    if (to.name == "Maintenance") {
      next({ name: "Home" });
    }
  }

  if (to.name == "Home" || to.name == "Login") {
    if (store.getters["authStore/isLoggedIn"]) {
      next({ name: "Search" });
    } else {
      next();
    }
  } else if (
    to.name == "BookDemo" ||
    to.name == "Logout" ||
    to.name == "HelpCenter" ||
    to.name == "ContactUs" ||
    to.name == "PrivacyPolicy" ||
    to.name == "TermsAndConditions" ||
    to.name == "RefundPolicy" ||
    to.name == "AboutUs" ||
    to.name == "AcceptRemarketingOpportunity" ||
    to.name == "OffsiteFormCustomer" ||
    to.name == "RejectRemarketingOpportunity" ||
    to.name == "PasswordReset"
  ) {
    next();
  } else if (store.state.authStore.isSuperUser) {
    if (to.name != "SuperUserPermissions" && to.name != "SuperUserGeorgeReport" && to.name != "SuperUserNewDealer") {
      next({ name: "SuperUserPermissions" });
    } else {
      next();
    }
  } else if (store.state.dealershipStore.currentDealership) {
    if (to.name !== "SwitchDealer") {
      if (store.state.dealershipStore.currentDealer) {
        next();
      } else {
        next({ name: "SwitchDealer" });
      }
    } else {
      next();
    }
  } else if (store.state.resellerStore.currentReseller) {
    next();
  } else {
    if (store.getters["authStore/isLoggedIn"]) {
      if (to.name !== "FirstTimeLogin") {
        next({ name: "FirstTimeLogin" });
      } else {
        next();
      }
    } else {
      if (to.name !== "Login") {
        next({ name: "Login", query: { next: to.path } });
      } else {
        next();
      }
    }
  }
  // } else {
  //   if (to.name !== "Login") {
  //     if (store.state.resellerStore.currentReseller) {
  //       next();
  //     } else {
  //       next({ name: "Login", query: { next: to.path } });
  //     }
  //   } else {
  //     next();
  //   }
  // }
});

export default router;
